import React, { Component } from 'react';
import { IoMdLogIn, IoMdLogOut } from 'react-icons/io';
import QrReader from 'modern-react-qr-reader'
import axios from "axios";



import Branding from './Branding';

class Home extends Component {

    constructor(props){
        super(props)
        this.state = {
            delay: 100,
            resolution: 1000,
            result: '',
            qrRead: false,
            qrCodeImage: '',
        }

        this.handleScan = this.handleScan.bind(this)
    }

    handleScan = qrResult => {


        if(this.state.qrRead === false && this.props.homeMessage === '' && qrResult) {
            this.setState({
                result: this.props.t('#CHEKKINGQRCODE#'),
                qrRead: true,
            });

            const url       = qrResult.split('accessKey=');

            let data        = JSON.parse(localStorage.getItem('displaySettings'));

            data.accessKey  = url[1];


            axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: this.props.domain + '/core/connect.php?action=connectInvitation',
                data: data

            }).then((responseObject) => {

                if(responseObject.data.hasOwnProperty('card')) {

                    const visitorCheckedIn = responseObject.data.card.visitor_checked_in;

                    // if(visitorCheckedIn === false || data.check_in_list_id !== responseObject.data.card.idList) {

                    if(visitorCheckedIn === false || data.guestlist_id === responseObject.data.card.idList) {

                        const idList    = data.check_in_list_id;
                        const message   = '#CHECKIN#';
                        this.scanResult('in', responseObject.data.card.id, responseObject.data.card.name, idList, message, data);

                    } else if(visitorCheckedIn === true) {

                        const idList = data.check_out_list_id;
                        const message = '#CHECKOUT#';
                        this.scanResult('out', responseObject.data.card.id, responseObject.data.card.name, idList, message, data);

                    } else {

                        this.props.setHomeMessage(this.props.t('#NOGUESTBYQRCODEFOUND#'));
                        this.props.setStyles();

                        this.setState({
                            result: '',
                            qrRead: false,
                        });
                    }


                } else {

                    this.props.setHomeMessage(this.props.t('#NOGUESTBYQRCODEFOUND#'));
                    this.props.setStyles();

                    this.setState({
                        result: '',
                        qrRead: false,
                    });

                }




            }).catch((error) => {

                console.log(error)

                this.props.setHomeMessage(this.props.t('An error occurred!'));
                this.props.setStyles();

                this.setState({
                    result: '',
                    qrRead: false,
                });

            });

        }


    }


    handleError(err){
        console.error(err)
    }

    scanResult(status, guestId, guestName, idList, message, data){

        data.cardId = guestId;
        data.idList = idList;
        data.in_out = status;

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: this.props.domain + '/core/connect.php?action=updateVisitor',
            data: data

        }).then((responseObject) => {

            var text =  this.props.t(message).replace('#NAME#', guestName);
            this.props.setHomeMessage(text);
            this.props.setStyles();

            this.setState({
                result: '',
                qrRead: false,
            });

        }).catch((error) => {
            console.log(error)
        });


    }


    setHomeStyle = () => {
        this.props.setStyles();
    };

    componentDidMount() {

        document.getElementById("app-window").focus();

        this.props.setStyles();
        window.addEventListener('resize', this.setHomeStyle);

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setHomeStyle)
    }

    render() {

        const {colors, background, clientInfoText, languages, languageSelect, homeMessage, screen, mainStyle, qrReader, qrFlip, qrFrontRear, qrImage, qrImageURL, touchless} = this.props;

        const colorFront    = {
            color: colors.colorFront,
            textShadow:  '0px 4px 3px ' + colors.colorFrontShadow + colors.colorFrontShadowTransparency,
            whiteSpace: "pre-wrap"
        }

        const BtnLarge = {
            color: colors.colorBtnText,
            backgroundColor: colors.colorBack + colors.buttonTransparency,
            cursor: 'pointer',
        }

        const colorPop = {
            color: colors.colorPopText,
            backgroundColor: colors.colorPopBack + colors.colorPopTransparency,
            whiteSpace: "pre-wrap"
        }

        const qrFrameStyle = {
            backgroundColor: colors.colorFront,
        }

        const qrImageText = {
            color: colors.colorFront,
        }

        const styleQrFlip = {
            transform: "scaleX(" + qrFlip + ")"
        }

        return (

            <div id="app-window" style={ background } className="full-height">
                <div className="container-fluid d-flex align-items-center">
                    <div className="row d-flex justify-content-center vw-100 text-center">

                        <div id="content" className="main-content vw-100 text-center" style={mainStyle}>
                            {homeMessage !== "" &&
                                <div className="content col-12 col col-sm-10 col-lg-6 btn-wrapper" onClick={() => this.props.clearHomeMessage()}>
                                    <h3 id="home-message" style={colorPop} dangerouslySetInnerHTML={{__html: homeMessage}}></h3>
                                </div>
                            }

                            {homeMessage === "" && touchless === 0 && qrImage === 1 &&
                                <div id="qr-image-wrapper">
                                    <img src={ qrImageURL } alt="QR Code"/>
                                    <div style={qrImageText}>{this.props.t('Scan QR Code for Touchless Check In/Out')}</div>

                                </div>
                            }

                            {homeMessage === "" &&
                                <div id="home-wrapper" className="content col-12 col-sm-12 col-lg-10 text-center">

                                    <h1 style={ colorFront } dangerouslySetInnerHTML={{__html: this.props.t("#WELCOME#")}}></h1>
                                    <div className="btn-wrapper col-12 col-sm-8 col-lg-5 p-4">

                                        <div id="btn-check-in" className="btn-large d-block p-4 mb-4" style={ BtnLarge } onClick={() => this.props.changeScreen('check-in')}>
                                            {this.props.t('Check In')} <IoMdLogIn />
                                        </div>
                                        <div id="btn-check-out" className="btn-large d-block p-4" style={ BtnLarge } onClick={() => this.props.changeScreen('check-out')}>
                                            {this.props.t('Check Out')} <IoMdLogOut />
                                        </div>

                                    </div>

                                </div>
                            }

                            {homeMessage === "" && touchless === 0 && qrReader === 1 &&
                                <div id="qr-wrapper" className="content col-12 text-center">
                                    {(this.state.qrRead === false && homeMessage === "") &&
                                        <div id="qr-container">
                                        <div id="qr-reader" style={ styleQrFlip }>

                                            <QrReader
                                                delay={this.state.delay}
                                                resolution={this.state.resolution}
                                                facingMode={ qrFrontRear }
                                                onError={this.handleError}
                                                onScan={this.handleScan}
                                                style={{ width: '100%' }}
                                                />

                                        </div>
                                        <div id="qr-frame" style={ qrFrameStyle }>
                                        </div>
                                        </div>
                                    }
                                    <p>{this.state.result}</p>
                                </div>
                            }



                        </div>

                    </div>

                </div>

                <Branding
                    colorFront       ={colorFront}
                    clientInfoText   ={clientInfoText}
                    languages        ={languages}
                    languageSelect   ={languageSelect}
                    changeLanguage   ={this.props.changeLanguage.bind(this)}
                    t                ={this.props.t.bind(this)}
                    screen           ={screen}
                    />

            </div>

        );
    }
}

export default Home;







