import React, { Component } from 'react';
import logoVisitors from './images/logo.png';
import { IoIosSettings } from 'react-icons/io';



class SplashScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logo: '',
            logoCustomer: '',
        };

    }

    setSplashStyle = () => {
        this.props.setStyles();
    };

    componentDidMount() {

        const data = JSON.parse(localStorage.getItem('displaySettings'));

        if (data.hasOwnProperty('account')) {

            const logoCustomer = data.account.splash_logo;

            if (logoCustomer !== '') {
                this.setState({ logo: "data:image/png;base64, " + logoCustomer });
            } else {
                this.setState({ logo: logoVisitors });
            }
        }

        this.props.setStyles();

        window.addEventListener('resize', this.setSplashStyle);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setSplashStyle)
    }

    render() {

        const { hostUrl, background, mainStyle } = this.props;

        return (

            <div id="app-window" style={ background } className="full-height">
                <div className="container-fluid d-flex align-items-center">
                    <div className="row d-flex justify-content-center vw-100">
                       <div className="content col-12 col col-sm-6 col-lg-3 text-center">
                           <div id="logo-wrapper" className="main-content" style={mainStyle}>
                            <img id="logo" src={this.state.logo} alt="Logo" />
                          </div>
                       </div>
                   </div>
               </div>

                {hostUrl === null && (
                    <div className="content col-12 fixed-bottom mb-4 text-center">
                        <div className="settings" onClick={() => this.props.setup()}>
                            <IoIosSettings />
                        </div>
                    </div>
                )}


            </div>
        );
    }
}

export default SplashScreen;