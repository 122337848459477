import React, {Component} from 'react';
import { IoMdInformationCircle, IoMdGlobe} from 'react-icons/io';
import { PopupboxManager, PopupboxContainer } from 'react-popupbox';
import axios from "axios";
import "react-popupbox/dist/react-popupbox.css"

class Branding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            brandText: '',
            brandImage: '',
            brandStyle: {}
        };

    }

    componentDidMount() {

        const data = localStorage.getItem('displaySettings');

        var object;

        if (data != null) {
            object = JSON.parse(data)
        } else {
            object = {};
        }

        if (object.hasOwnProperty('account')) {

            const account = object.account;

            this.setState({brandText: account.brand_text});
            this.setState({brandImage: account.brand_image});

            var displayStyle = "block";

            if (account.brand_label === '0') {
                displayStyle = "none";
            }

            const brandStyle = {
                display: displayStyle,
                color: account.brand_text_color,
                backgroundColor: account.brand_background_color + account.brand_background_transparency,
            }
            this.setState({brandStyle: brandStyle});

        } else {

            console.log("no account");

            const brandStyle = {
                paddingTop: '10px',
                color: '#000',
                backgroundColor: '#ffffff80',
            }
            this.setState({brandStyle: brandStyle});

        }

    }

    showInfoText(text) {
        const content = (
            <div dangerouslySetInnerHTML={{__html: this.props.t(text)}}></div>
        )
        PopupboxManager.open({
            content,
            config: {
                titleBar: {
                    enable: true,
                    text: this.props.t('Information')
                },
                fadeIn: true,
                fadeInSpeed: 500
            }
        })
    }

    changeLanguageShow(languages) {

        if(languages.length === 0) {

            const data = {}

            axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: 'https://visitorsfortrello.com/core/connect.php?action=getLanguages',
                data: data

            }).then((response) => {
                languages = response.data;
                this.changeLanguagePop(languages);

            });
        } else {
            this.changeLanguagePop(languages);
        }


    }

    changeLanguageSet(locale) {
        this.props.changeLanguage(locale);
        PopupboxManager.close();
    }

    changeLanguagePop(languages) {

        let languageList = [];

        Object.keys(languages).map(function(objectKey, index) {
            //var value = languages[objectKey];
            var image = "data:image/png;base64, " + languages[objectKey].icon;
            languageList.push(<div key={index} className="changeLanguageBtn" onClick={() => this.changeLanguageSet(objectKey)}><span className="icon"><img src={image} alt={languages[objectKey].name} /></span>{languages[objectKey].name}</div>);
        }.bind(this));


        const content = (<div>{languageList}</div>);

        PopupboxManager.open({
            content,
            config: {
                titleBar: {
                    enable: true,
                    text: this.props.t('Select Language')
                },
                fadeIn: true,
                fadeInSpeed: 500
            }
        })

    }



    render () {

        const {colorFront, screen}  = this.props;
        var clientInfoText          = "";
        var {languages, languageSelect }   = this.props;

        if(screen === "home") {
            clientInfoText = this.props.t('#INFOTEXT#');

        } else if(screen === "setup") {
            clientInfoText = this.props.t('#SETUP#');
            languageSelect = '1';

        } else if(screen === "abort-session") {
            clientInfoText = '#ABORTSESSION#';
            languageSelect = '1';

        } else {
            clientInfoText = '';
        }

        var brandImageSrc;

        if (this.state.brandImage !== '') {
            brandImageSrc = "data:image/png;base64, " + this.state.brandImage;
        } else {
            brandImageSrc = "";
        }


        return (

            <div id="branding" className="transparent transition vw-100 pr-2">
                <div id="brand-label" className="ml-3" style={ this.state.brandStyle }>
                    { brandImageSrc !== '' && (
                        <span id="brand-image"><img src={ brandImageSrc } alt="brand"/></span>
                    )}
                    <span id="brand-text" className="align-middle not-visible">{ this.state.brandText }</span>
                </div>

                {(clientInfoText !== '#INFOTEXT#' && clientInfoText !== '') && (
                    <div id="info" className="mr-1" style={ colorFront } onClick={() => this.showInfoText(clientInfoText)}>
                        <IoMdInformationCircle/>
                    </div>
                )}
                {languageSelect === 1 && (
                    <div id="language" className="mr-1" style={ colorFront } onClick={() => this.changeLanguageShow(languages)}>
                        <IoMdGlobe/>
                    </div>

                )}
                <PopupboxContainer />

            </div>

        )
    }
}

export default Branding;