import React, { Component } from 'react';
//import { navigate } from '@reach/router';
import axios from "axios";
import { MdSync } from 'react-icons/md';

import Branding from './Branding';

class Setup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hostCode: '',
            clientCode: '',
            displaySettings: [],
            message: '',

        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.validateAccess = this.validateAccess.bind(this);


    }

    componentDidMount(){

        const {hostUrl} = this.props;

        if(hostUrl) {
            this.setState({ hostCode: hostUrl });
        }

        const displaySettings = localStorage.getItem('displaySettings');

        if (displaySettings && displaySettings.host_code === hostUrl) {
            this.setState({ clientCode: displaySettings.client_code });
        }

        if(displaySettings) {
            this.setState({ displaySettings: displaySettings });
        }
    }

    handleChange(e) {
        const itemName = e.target.name;
        const itemValue = e.target.value;
        this.setState({ [itemName]: itemValue });
    }

    validateAccess() {

        const timer = setInterval(function(){
            var data = JSON.parse(localStorage.getItem('displaySettings'));

            axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: this.props.domain + '/core/connect.php?action=connectClient',
                data: data

            }).then((responseObject) => {

                localStorage.setItem('displaySettings', JSON.stringify(responseObject.data));

                var response = responseObject.data;

                if(response.client_code !== this.state.clientCode) {

                    console.log('client rejected');

                    this.setState({ message: 'Request rejected!' });
                    this.setState({ clientCode: '' });

                    localStorage.clear();

                    clearInterval(timer);

                } else if(response.client_accepted === '1') {

                    console.log('client accepted');
                    clearInterval(timer);

                    this.props.setDisplayValues(response);

                }

            }).catch((error) => {

                console.log(error)

            });


        }.bind(this), 2000);

    }

    handleSubmit(e) {

        e.preventDefault();

        this.setState({ message: '' });

        // client_code: this.state.clientCode,

        const data = {
            host_code: this.state.hostCode,
            get_code: 'true',
        }

        this.props.loadClientValues(data).then(function(responseObject) {

            localStorage.setItem('displaySettings', JSON.stringify(responseObject.data));

            var response = responseObject.data;

            if(response.client_accepted === '1') {

                console.log('client accepted');
                this.props.setDisplayValues(response);

            } else if(response.id) {

                this.setState({ clientCode: response.client_code });

                this.validateAccess();

            } else {

                this.setState({ message: response.message });

            }

        }.bind(this))

    }

    render() {

        const {languages, languageSelect} = this.props;

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        return (

                <div id="app-window" className="visitor-background">
                    <div className="container-fluid d-flex full-height align-items-center">
                        <div className="row d-flex justify-content-center vw-100">
                            <div className="col-12 col-sm-8 col-lg-4 text-center">
                                <div id="content" className="p-4">
                                    <div id="message">{this.props.t(this.state.message)}</div>
                                    {this.state.clientCode === "" &&
                                        <form className="mt-3" onSubmit={this.handleSubmit}>
                                            <section className="form-group">
                                                <label
                                                    className="form-control-label sr-only"
                                                    htmlFor="hostCode"
                                                    >
                                                    Name
                                                </label>
                                                <input
                                                    required
                                                    className="form-control form-control-admin p-4"
                                                    type="text"
                                                    id="hostCode"
                                                    name="hostCode"
                                                    placeholder={this.props.t('Host Code')}
                                                    value={ this.state.hostCode }
                                                    onChange={this.handleChange}
                                                    />
                                            </section>

                                            <div className="form-group text-right mb-0">
                                                <button className="btn-admin" type="submit">
                                                    {this.props.t('Connect')} <MdSync />
                                                </button>
                                            </div>
                                        </form>
                                    }
                                    {this.state.clientCode !== "" &&
                                        <div>
                                            <h1>{ this.props.t('Waiting for approval') }.</h1>
                                            <p>{ this.props.t('Please accept this display in your Visitors administration') }.</p>
                                            <p>{ this.props.t('Code for this display:') } <strong>{this.state.clientCode}</strong></p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <Branding
                        languages={languages}
                        languageSelect={languageSelect}
                        changeLanguage={this.props.changeLanguage.bind(this)}
                        t={this.props.t.bind(this)}
                        />
                </div>

        );
    }
}

export default Setup;